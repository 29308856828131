import { createSlice, createAction } from "@reduxjs/toolkit";

export const revertAll = createAction("REVERT_ALL");

const initialState = [];
const orderSlice = createSlice({
  name: "orderSlice",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    addOrder(state, action) {
      state.push(action.payload);
    },
  },
});

export const { addOrder } = orderSlice.actions;
export default orderSlice.reducer;

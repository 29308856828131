import React from "react";

import ReactDOM from "react-dom/client";

import { Provider } from "react-redux";

import { PersistGate } from "redux-persist/integration/react";

import { registerLicense } from "@syncfusion/ej2-base";

// import App from "./App";
import Dev from "./Dev";

import store, { persistor } from "./store";

import "./i18n";

import "./assets/styles/index.scss";

registerLicense(process.env.REACT_APP_SYNCFUSION_LICENCE);


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
        {/* <App /> */}
        <Dev />
    </PersistGate>
  </Provider>
);


import { createSlice } from "@reduxjs/toolkit";

const initialState = "ar";
const langSlice = createSlice({
  name: "langSlice",
  initialState,
  reducers: {
    setLang(state, action) {
      return action.payload;
    },
  },
});

export const { setLang } = langSlice.actions;
export default langSlice.reducer;

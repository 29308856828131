import { combineReducers } from "@reduxjs/toolkit";

import orderSliceReducer from "./orderSlice";
import langSliceReducer from "./langSlice";

const rootReducer = combineReducers({

  orderSlice: orderSliceReducer,
  langSlice: langSliceReducer,
});

export default rootReducer;

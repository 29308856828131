import i18n from "i18next";

import { initReactI18next } from "react-i18next";

import ar from "./locales/ar.json";

import en from "./locales/en.json";

import fr from "./locales/fr.json";

i18n.use(initReactI18next).init({
  resources: {
    ar: { translation: ar },
    fr: { translation: fr },
    en: { translation: en },
  },
  lng: "ar",
  fallbackLng: "ar",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
